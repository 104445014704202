<template>
  <div class="page-container">
    <van-swipe :autoplay="3000" indicator-color="red">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img class="swipe-img" v-lazy="image" alt="swipe" />
      </van-swipe-item>
    </van-swipe>
    <van-grid column-num="5" :border="false" :clickable="true">
      <van-grid-item text="简介" url="/page/jianjie">
        <template slot="icon">
          <svg-icon icon-class="school" class-name="home-gird-icon" />
        </template>
      </van-grid-item>
      <van-grid-item text="章程" url="/page/zhangcheng">
        <template slot="icon">
          <svg-icon icon-class="document" class-name="home-gird-icon" />
        </template>
      </van-grid-item>
      <van-grid-item text="指南" url="/page/zhinan">
        <template slot="icon">
          <svg-icon icon-class="guide" class-name="home-gird-icon" />
        </template>
      </van-grid-item>
      <van-grid-item text="协议" url="/page/xieyi">
        <template slot="icon">
          <svg-icon icon-class="agreement" class-name="home-gird-icon" />
        </template>
      </van-grid-item>
      <van-grid-item text="联系" url="/page/lianxi">
        <template slot="icon">
          <svg-icon icon-class="contact" class-name="home-gird-icon" />
        </template>
      </van-grid-item>
    </van-grid>
    <van-swipe class="donate-stats" :show-indicators="false" :autoplay="3000">
      <van-swipe-item v-for="(item, idx) in statsList" :key="idx">{{
        item
      }}</van-swipe-item>
    </van-swipe>
    <div class="news-tool">
      <div class="category-this">
        <span> <svg-icon icon-class="category" /> {{ newsCategoryTitle }}</span>
      </div>
      <van-dropdown-menu class="category-select">
        <van-dropdown-item
          class="category-select-child"
          title="点击筛选"
          v-model="newsCategory"
          :options="newsCategoryOptions"
        />
      </van-dropdown-menu>
    </div>
    <div class="news-list">
      <van-pull-refresh v-model="newsLoading" @refresh="fetchNewsList">
        <news-item
          v-for="(item, idx) in newsList"
          :key="idx"
          :nid="item.id"
          :title="item.title"
          :image="item.image ? item.image : news_image_default"
          :category="item.category"
          :date="item.date"
        />
      </van-pull-refresh>
    </div>
    <sdut-banner />
    <tabbar />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import NewsItem from "@/components/NewsItem";
import HomeImg1 from "@/assets/images/1_1.png";
import HomeImg2 from "@/assets/images/1_2.png";
import HomeImg3 from "@/assets/images/1_3.png";

import SdutBanner from "@/components/SdutBanner";

import { getNewsList, getNewsCategory } from "@/api/news";
import { getHomeData } from "@/api/other";

export default {
  name: "HomePage",
  components: { Tabbar, NewsItem, SdutBanner },
  data() {
    return {
      images_old: [HomeImg1, HomeImg2, HomeImg3],
      images: [
        "https://60.sdut.edu.cn/_upload/tpl/00/8d/141/template141/images/1_3.png",
        "https://60.sdut.edu.cn/_upload/tpl/00/8d/141/template141/images/1_2.png",
      ],
      news_image_default: HomeImg1,
      newsCategory: "all",
      newsCategoryOptions: [{ text: "所有动态", value: "all" }],
      newsCategoryTitle: "所有动态",
      newsLoading: false,
      newsList: [],
      statsList: [],
    };
  },
  watch: {
    newsCategory(val) {
      if (val === "all") {
        this.newsCategoryTitle = "所有动态";
      } else {
        this.newsCategoryTitle = this.newsCategoryOptions[val].text;
      }
      this.fetchNewsList();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchCategory();
      this.fetchNewsList();
      this.fetchHomeData();
    },
    fetchNewsList() {
      this.newsLoading = true;
      getNewsList("all", this.newsCategory).then((response) => {
        this.newsList = response.list;
        this.newsLoading = false;
      });
    },
    fetchCategory() {
      getNewsCategory().then((response) => {
        response.forEach((item) => {
          this.newsCategoryOptions.push({
            text: item.name,
            value: item.id,
          });
        });
      });
    },
    fetchHomeData() {
      getHomeData().then((response) => {
        this.statsList = response.stats;
      });
    },
  },
};
</script>

<style>
.swipe-img {
  height: 200px;
  width: 100%;
}

.donate-stats {
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  background-color: #ffffff;
  margin-top: 5px;
}

.news-list {
  margin-top: 0.2rem;
}

.home-gird-icon {
  font-size: 1.6rem;
  margin-bottom: 0.2rem;
  color: red;
}

.news-tool {
  width: 100%;
  height: 2rem;
  margin-top: 0.15rem;
  background-color: #ffffff;
}

.category-select {
  width: 10rem;
  height: 2rem;
  float: right;
}

.category-select > .van-dropdown-menu__bar {
  height: 2rem !important;
  box-shadow: none !important;
}

.category-select > .van-ellipsis {
  font-size: 0.7rem !important;
}

.category-select-child {
  width: 10rem;
  height: 1rem;
}

.category-this {
  float: left;
  width: 10rem;
  height: 2rem;
  margin-left: 2rem;
}

.category-this span {
  line-height: 2rem;
  font-size: 1rem;
  font-weight: bold;
}
</style>
