<template>
  <router-link :to="`/article/`+nid">
    <div class="news-item">
      <div class="news-box">
        <div class="news-image">
          <img :src="image" alt="">
        </div>
        <div class="news-detail">
          <div class="news-title">
            <p><span class="category-tag">{{category}}</span>
              <svg-icon icon-class="trg-right" class-name="category-tag-arrow" />{{title}}</p>
          </div>
          <div class="news-stats">
            <p>{{date}}</p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    nid: Number,
    image: String,
    title: String,
    date: String,
    category: String
  }
}
</script>

<style>
.news-box {
  display: flex;
  background-color: #ffffff;
  height: 5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 2px 0;
  padding: 0.4rem 1rem 0 1rem;
}

.news-box > div {
  height: 4.5rem;
  margin: 0;
  padding: 0;
}

.news-image {
  width: 8rem;
}

.news-image img {
  width: 100%;
  height: 100%;
}

.news-detail {
  flex: 1;
  margin-left: 1rem !important;
}

.news-item p {
  margin: 0;
}

.news-title {
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-title p {
  font-size: 0.8rem;
  line-height: 1.5rem;
}

.news-stats {
  margin-top: 0.5rem;
}

.news-stats p {
  font-size: 0.6rem;
}

.category-tag {
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 1.2rem;
  background-color: red;
  text-align: center;
  vertical-align: baseline;
  line-height: 1.2rem;
  color: #ffffff;
}

.category-tag-arrow {
  color: red;
  margin: 0;
  margin-left: -0.55rem;
}
</style>
