import request from '@/utils/request'

export function getNewsList (page = null, category = null) {
  return request({
    url: '/news/list',
    method: 'get',
    params: {
      page: page,
      category: category
    }
  })
}

export function getNewsCategory () {
  return request({
    url: '/news/category',
    method: 'get'
  })
}

export function getNewsData (news) {
  return request({
    url: '/news/data',
    method: 'get',
    params: {news: news}
  })
}
