<template>
  <van-tabbar v-model="activeTab" @change="onTabChange">
    <van-tabbar-item name="HomePage" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item name="ProjectPage" icon="orders-o">项目</van-tabbar-item>
    <van-tabbar-item name="MinePage" icon="friends-o">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data () {
    return {
      activeTab: this.$route.name
    }
  },
  methods: {
    onTabChange (index) {
      this.$router.push({ name: index })
    }
  }
}
</script>
<style>
.van-tabbar-item--active{
  color: red!important;
}
</style>
